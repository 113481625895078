<template>
    <div class="nk_consulting">
      <div class="bac_style"></div>
      <div class="consultingContent serviceFont">
        <div class="content_tittle">
          <div :class="tab_state == 1?'nk_style public_style tab_style':'nk_style public_style'" @click="btn_tab(1)">
            新闻推荐
            <span class="underline" style="display:block" v-if="tab_state == 1"></span>
          </div>
          <!-- <div :class="tab_state == 2?'public_style tab_style':'public_style'" @click="btn_tab(2)">
            新闻动态
             <span class="underline" style="display:block" v-if="tab_state == 2"></span>
          </div> -->
          <div class="toView" @click="lookMore">更多<van-icon name="arrow" /></div>
        </div>
        <div class="nankaiContent">
          <div v-for="i in contentList" :key="i.id" class="nankaiItem" @click="onsetList(i)">
            <!-- <div class="nankaiImg">
              <img v-lazy="i.coverPhoto" alt="">
            </div> -->
            <div class="nankaiTittle">
              <div class="matter">{{i.title}}</div>
              <div class="nankaiTag">
                <van-tag color="#E3F7FF" text-color="#0073C0" v-for="(item,index) in i.tagInfo" :key="index">{{item}}</van-tag>
              </div>
              <div class="time">{{i.publishTime}}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="bac_style"></div> -->
    </div>
</template>

<script>
import { getContentRecList } from '@/api/home.js'
export default {
data() {
    return {
      // tab栏切换
      tab_state:1,
      contentList:[]
    }
  },
  created(){
    this.nkInit()
  },
  watch:{

  },
  methods:{
    onsetList(item){
      this.$store.commit('setTopTittle',item.title)
      // 首页数据
      this.$store.commit('setMenulist',item)
      // 传递id
      this.$router.push({path:'/nkopen',query:{categoryId:item.id}})

      this.$store.commit('setDetailsId',item.id)
      
      this.$store.commit('setStyleType',1)
      
      this.$store.commit('setMenuType','lanmu')
    },
    async nkInit(){
          const ContentRecListData = await getContentRecList({
            "pageNumber": 1,
            "pageSize": 5,
            "title":''
          })
          this.contentList = ContentRecListData.data.data.rows
          // console.log(this.contentList)
          this.contentList.map(item => {
            item.tagInfo = item.tagInfo.split(",")
          })
        
    },
    // 更多
    lookMore(){
      localStorage.setItem('moresList','新闻推荐')
      this.$router.push({path:'/moreList',query:{type:'nk'}})
    },
    
    // 切换tab
    // btn_tab(i){
    //   // this.tab_state = i
    //   console.log(lightAppJssdk)
    //   lightAppJssdk.map.getLocation ({ 
    //         latitude: "39.903578", // 纬度
    //         longitude: "116.473565", // 经度
    //        success:function(data){
    //          console.log(data)
    //        },
    //        fail:function(data){
    //          console.log(data)
    //        }
    //        });
    // },
  }
}
</script>

<style lang="less" scoped>
  @import '../home.less';
</style>