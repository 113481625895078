<template>
  <div class="handleAffairs serviceFont">
      <div class="bac_style"></div>
      <div class="handleAffairsFont">
        服务推荐
        <span class="handleAffairsFonts" @click="lookMore"><span style="vertical-align: text-top;">更多</span><van-icon name="arrow" /></span>
      </div>
      <div class="handleAffairsNav" v-if="handeldata.length > 0">
        <div class="handleAffairsNavItem" v-for="i in handeldata" :key="i.id" @click="onMoudel(i)">
          <img v-lazy="i.columnIcon" alt="">
          <div>{{i.columnName}}</div>
        </div>
      </div>
      <div class="handleImg" v-else>
        <img src="@/assets/zanwu.png" alt="">
      </div>
    </div>
</template>

<script>
import { getServiceRecList } from "@/api/home.js";
export default {
  name:"handleAffairs",
  data() {
    return {
      handeldata:[]
    }
  },
  created(){
    this.haninit()
  },
  methods:{
    lookMore(){
      // console.log(item)
      localStorage.setItem('moresBtn','服务推荐')
      this.$router.push({path:'/moreBtn',query:{columnType:'bs'}})
    },
    async haninit(){
      const RecList = await getServiceRecList({
        "pageNumber": 1,
        "pageSize": 4
      })
      this.handeldata = RecList.data.data.rows
    },
    // 点击模块
    async onMoudel(item){
      // console.log(item)
      if(item.columnType == 3){
          location.href = item.columnLink
          // lightAppJssdk.weex.openWeexVc({
          //   title :item.columnName,//顶部导航栏标题
          //   url : item.columnLink, //weex页面地址
          //   success: function(data) {
          //       console.log(data);
          //   },
          //   fail: function(data) {
          //       console.log('falied');
          //   }
          //   });
        }else{
          this.$store.commit('setTopTittle',item.columnName)
          // 栏目
          this.$store.commit('setMenuType','leimu')
          // 首页数据
          this.$store.commit('setMenulist',item)
          // 传递id
          this.$router.push({path:'/chooseList',query:{categoryId:item.id,columnType:item.columnType,columnAttr:item.columnAttr,columnStyle:item.columnStyle}})
        }
    },
  }
}
</script>

<style lang="less" scoped>
  @import '../home.less';
</style>