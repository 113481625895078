<template>
    <div class="nk_consulting">
      <div class="bac_style"></div>
      <div class="consultingContent serviceFont">
        <div class="content_tittle">
          <div :class="tab_state == 1?'nk_style public_style tab_style':'nk_style public_style'" @click="btn_tab(1)">
            {{tittle}}
            <span class="underline" style="display:block" v-if="tab_state == 1"></span>
          </div>
          <!-- <div :class="tab_state == 2?'public_style tab_style':'public_style'" @click="btn_tab(2)">
            新闻动态
             <span class="underline" style="display:block" v-if="tab_state == 2"></span>
          </div> -->
          <div class="toView" @click="lookMore">更多<van-icon name="arrow" /></div>
        </div>
        <!-- v-if="contentList.length>0" -->
        <div class="nankaiContent" v-if="contentList.length>0">
          <div v-for="i in contentList" :key="i.id" class="nankaiItem" @click="onsetList(i)">
            <div class="nankaiImg" v-if="listType == 1 || listType == 2">
              <img v-lazy="i.coverPhoto" alt="">
            </div>
            <div class="nankaiTittle">
              <div class="matter">{{i.title}}</div>
              <div class="nankaiTag">
                <van-tag color="#E3F7FF" text-color="#0073C0" v-for="(item,index) in i.tagInfo" :key="index">{{item}}</van-tag>
              </div>
              <div class="time" v-if="listType == 1 || listType == 3">{{i.publishTime}}</div>
            </div>
          </div>
        </div>
        <div style="position: relative;" v-else>
          <nodata></nodata>
        </div>
      </div>
      <!-- <div class="bac_style"></div> -->
    </div>
</template>

<script>
import { getInfoList } from '@/api/home.js'
export default {
  props:["dataFrom"],
data() {
    return {
      // tab栏切换
      tab_state:1,
      contentList:[],
      lookId:'',
      tittle:'',
      listType:''
    }
  },
  created(){
    this.nkInit()
  },
  watch:{
    dataFrom:{
      deep:true,
      immediate:true,
      handler:function(newdata,olddata){
        // console.log(this.dataFrom)
      }
    }
  },
  computed:{
    advertisingId(){
      return this.$store.state.advertisingId
    }
  },
  methods:{
     onsetList(item){
       this.$store.commit('setTopTittle',item.title)
      // 首页数据
      this.$store.commit('setMenulist',item)
      // 传递id
      this.$router.push({path:'/nkopen',query:{categoryId:item.id}})

      this.$store.commit('setDetailsId',item.id)

      this.$store.commit('setStyleType',1)
      
      this.$store.commit('setMenuType','lanmu')
    },
    //
    async nkInit(){
          for(let i in this.advertisingId){
            // console.log(this.advertisingId)
              if(this.advertisingId[i].navCode == 99){
                const ContentRecListData = await getInfoList({
                    "columnId":this.advertisingId[i].relaColumn,
                    "pageNumber": 1,
                    "pageSize": 5
                  })
                  this.contentList = ContentRecListData.data.data.contentList
                  this.tittle = ContentRecListData.data.data.moduleTitle
                  this.lookId = ContentRecListData.data.data.newColumnId
                  this.listType = ContentRecListData.data.data.listType
                  if(this.contentList){
                    this.contentList.map(item => {
                      item.tagInfo = item.tagInfo.split(",")
                    })
                  }
              }
          }
        
    },
    // 更多
    lookMore(){
      console.log(this.tittle)
      localStorage.setItem('moresList',this.tittle)
      this.$router.push({path:'/moreList',query:{type:'xx',xxId:this.lookId,listType:this.listType}})
    },
  }
}
</script>

<style lang="less" scoped>
  @import '../home.less';
</style>