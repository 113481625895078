<template>
  <div>
    <div class="education serviceFont" v-for="(item) in moudel_nav" :key="item.id" >
      <div class="bac_style"></div>
      <div class="educationFont">
        {{item.columnName}}
        <span class="educationFonts" @click="lookMore(item)"><span style="vertical-align:text-top;">更多</span><van-icon name="arrow" /></span>
      </div>
      <div class="educationNav">
        <div v-for="(it) in item.childrens" :key="it.id" @click="onMoudel(it)">
          <img v-lazy="it.columnIcon" alt="">
          <span style="display: block">{{it.columnName}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getServiceMenuList } from '@/api/home.js'
export default {
data() {
  return {
    // 模块
      moudel_nav:[
        {
          tittle:'教育服务',
          appItem:[
            {
              name:"幼儿园教育",
              url:'youeryuan'
            },
            {
              name:"健身场馆",
              url:'jianshen'
            },
            {
              name:"妇幼保健院",
              url:'fuyoujiankangguanlipingtai'
            },
            {
              name:"妇幼保健院",
              url:'fuyoujiankangguanlipingtai'
            },
            {
              name:"妇幼保健院",
              url:'fuyoujiankangguanlipingtai'
            },
            {
              name:"妇幼保健院",
              url:'fuyoujiankangguanlipingtai'
            }
          ]
        },
        {
          tittle:'文体服务',
          appItem:[
            {
              name:"幼儿园教育",
              url:'youeryuan'
            },
            {
              name:"健身场馆",
              url:'jianshen'
            },
            {
              name:"妇幼保健院",
              url:'fuyoujiankangguanlipingtai'
            },
            {
              name:"妇幼保健院",
              url:'fuyoujiankangguanlipingtai'
            },{
              name:"幼儿园教育",
              url:'youeryuan'
            },
            {
              name:"健身场馆",
              url:'jianshen'
            },{
              name:"幼儿园教育",
              url:'youeryuan'
            },
            {
              name:"健身场馆",
              url:'jianshen'
            },
          ]  
        },
        {
          tittle:'健康服务',
          appItem:[
            {
              name:"幼儿园教育",
              url:'youeryuan'
            },
            {
              name:"健身场馆",
              url:'jianshen'
            },
            {
              name:"妇幼保健院",
              url:'fuyoujiankangguanlipingtai'
            },
            {
              name:"妇幼保健院",
              url:'fuyoujiankangguanlipingtai'
            },{
              name:"健身场馆",
              url:'jianshen'
            },
            {
              name:"妇幼保健院",
              url:'fuyoujiankangguanlipingtai'
            },
            {
              name:"妇幼保健院",
              url:'fuyoujiankangguanlipingtai'
            }
          ]
        },
      ]
  }
},
created(){
  this.init()
},
methods:{
    async init(){
      const menuList = await getServiceMenuList({
        "pageNumber": 1,
        "pageSize": 8
      })
      this.moudel_nav = menuList.data.data
    },
    // 更多
    lookMore(item){
      localStorage.setItem('moresBtn',item.columnName)
      localStorage.setItem('moresUrl',item.columnIcon)
      this.$router.push({path:'/moreBtn',query:{columnType:'fw',columnId:item.id}})
    },
    // 点击模块
    async onMoudel(item){
      if(item.columnType == 3){
        location.href = item.columnLink
        // lightAppJssdk.weex.openWeexVc({
        //   title :item.columnName,//顶部导航栏标题
        //   url : item.columnLink, //weex页面地址
        //   success: function(data) {
        //       console.log(data);
        //   },
        //   fail: function(data) {
        //       console.log('falied');
        //   }
        //   });
      }else{
        this.$store.commit('setTopTittle',item.columnName)
          // 栏目
        this.$store.commit('setMenuType','leimu')
        // 首页数据
        this.$store.commit('setMenulist',item)
        // 传递id
        // console.log(item)
        this.$router.push({path:'/chooseList',query:{categoryId:item.id,columnType:item.columnType,columnAttr:item.columnAttr,columnStyle:item.columnStyle}})
      }
    },
    // 动态图片
    image(url){
      return require('@/assets/'+url+'.png')
    },
}

}
</script>

<style lang="less" scoped>
  @import '../home.less';
</style>