<template>
  <div class="advertising">
    <div class="bac_style"></div>
    <img v-for="item in formDataImg" :key="item.id" v-lazy="item.coverPhoto" alt="" @click="btn(item.linkUrl)">
  </div>
</template>

<script>
import { getPromList } from "@/api/home.js";
export default {
props:["dataFrom","relaId"],
data() {
  return {
    images:'',
    formDataImg:[],
    newarr:[],
    i:0
  }
},
computed:{
  advertisingId(){
      return this.$store.state.advertisingId
    }
},
created(){
  this.init()
},
watch:{
 dataFrom:{
   deep:true,
   immediate:true,
   handler:function(newdata,olddata){
    this.images = this.dataFrom
   }
 }
},
methods:{
  async init(){
    const ContentRecListData = await getPromList({
      "idList":[this.relaId],
      "pageNumber": 1,
      "pageSize": 100
    })
    this.formDataImg = ContentRecListData.data.data
  },
  btn(url){
    window.location.href = url
  }
}
}
</script>

<style lang="less" scoped>
.advertising{
  // width: 9.476rem;
  height: 1.9rem;
  // margin: 0.5rem  auto;
  width: 100%;
  .bac_style{
    height: .213rem;
    background-color: #f7f7f7;
  }
  img{
    margin-top: 0.387rem;
    padding: 0 0.258rem;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }
}
</style>