<template>
      <div class="weathers">
        <div class="bac_style"></div>
        <div class="weather">
          <div class="weather_box">
            <div class="weather_tou"></div>
              <iframe  frameborder="0" scrolling="no" hspace="0" src="https://i.tianqi.com/?c=code&a=getcode&id=34&py=tianjin&icon=1"></iframe>
              <div class="tjRestrictions">
                <div class="xianxing">
                  <div style="color:#000">今日限行</div>
                  <div class="number">{{restrictions}}</div>
                </div>
              </div>
          </div>
        </div>
        <!-- <iframe width="100" height="100" frameborder="0" scrolling="no" hspace="0" src="https://i.tianqi.com/?c=code&a=getcode&id=31&icon=1"></iframe> -->
        <!-- <div class="weatherYear">
          <div>10</div>
          <div class="week">周五</div>
        </div>
        <div class="weatherMonth">
          <div>2021年9月</div>
          <div class="restrictions">今日限行  5/0</div>
        </div>
        <div class="temperature">
          <div>20°~30°<img src="@/assets/logo.png" alt=""></div>
          <div class="quality">空气质量：良</div>
        </div> -->
    </div>
</template>

<script>
import {getTrafficRestriction} from '@/api/home.js'
export default {
  name:"weather",
  data(){
    return{
      restrictions:''
    }
  },
  mounted(){
    this.init()
  },
  methods:{
    async init(){
      let data = await getTrafficRestriction()
      if(data.data.data){
        this.restrictions = data.data.data
      }else{
        this.restrictions = '无'
      }
    }
  }
}
</script>

<style lang="less" scoped>
  @import '../home.less';
  .tjRestrictions{
    width: 1.907rem;
    text-align: right;
    font-size: .32rem;
    color: #FF6E1C;
    height: 100%;
    .xianxing{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    .number{
      margin-top: .244rem;
      font-size: 0.427rem;
    }
  }
</style>